<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        small
        title="Cadastrar novo grupo de acesso"
        v-on="on"
      >
        <v-icon color="primary">
          mdi-plus-circle-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <span class="headline">Cadastro de Acesso</span>
      </v-toolbar>
      <v-container class="px-5">
        <v-card-text>
          <v-form
            ref="form"
            dense
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-select
                  v-model="activeGroup.id"
                  :items="roles"
                  item-text="name"
                  item-value="id"
                  label="Grupo*"
                  dense
                  :loading="false"
                  :rules="[rules.required]"
                  autocomplete="off"
                  validate-on-blur
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="name"
                  dense
                  autocomplete="off"
                  :rules="[rules.required]"
                  label="Nome*"
                  required
                  validate-on-blur
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            @click="CANCEL"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            @click="submitForm"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import RoleFormStore, { SUBMIT, CANCEL } from '@/store/modules/forms/role'
  // import { profileTransformer } from '../../utils/transformers'
  export default {
    name: 'RoleFormDialog',
    props: {
      grupo: {
        type: Number,
        default: null,
      },
    },
    data () {
      return {
        rules,
      }
    },
    computed: {
      ...mapState('role/dialog', {
        // showDialog: state => state.dialog,
      }),
      ...mapState('role', ['roles']),
      ...mapGetters('role', ['activeGroup']),
      ...mapFields('role/dialog', [
        'dialog',
        ...['name'].map(str => `form.${str}`),
      ]),
    },
    created () {
      if (!this.$store.state.role.dialog) { this.$store.registerModule(['role', 'dialog'], RoleFormStore) }
    },
    methods: {
      ...mapActions('role/dialog', [CANCEL, SUBMIT]),
      submitForm () {
        if (!this.$refs.form.validate()) {
          return null
        }
        this.SUBMIT()
      },
    },
  }
</script>
