import { getField, updateField } from 'vuex-map-fields'
import { Role } from '@/models/Role'
import Swal from 'sweetalert2'
import api from '@/api/api'

export const SUBMIT = 'SUBMIT'
export const READY = 'READY'
export const OPEN_FORM = 'OPEN_FORM'
export const CANCEL = 'CANCEL'
export const SET_DIALOG = 'SET_DIALOG'

export default {
  namespaced: true,
  state: () => ({
    form: new Role(),
    dialog: false,
    edit: false,
    edit_id: null,
  }),
  mutations: {
    updateField,
    [SET_DIALOG] (state, { dialog }) {
      state.dialog = dialog
    },
  },

  getters: {
    getField,
  },

  actions: {
    [CANCEL] ({ dispatch, commit, state }) {
      commit(SET_DIALOG, { dialog: false })
    },
    [SUBMIT] ({ dispatch, commit, state, rootState, rootGetters }) {
      const { name } = state.form
      const parent = rootGetters['role/activeGroup'].id
      const group = rootState.role.roles.find(role => role.id === parent)

      const form = {
        parent,
        name,
        description: name,
        permissions: group && group.permissoes ? group.permissoes.map((p, index) => p ? index : -1).filter(p => ~p) : [],
      }

      Swal.fire({
        title: 'Enviando dados!',
        timerProgressBar: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        willOpen: () => {
          Swal.showLoading()
        },
        didOpen: () => {
          api.cadastrarEntidade(form, 'role').then(
            data => {
              Swal.fire({
                title: 'Acesso cadastrado com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })

              commit(
                'role/ADD_ROLE',
                {
                  role:
                  {
                    id: data.id,
                    name: data.description,
                    permissoes: data.permissions,
                    parent: data.parent,
                  },
                },
                { root: true })
            },
            error => {
              Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: error.errors ? error.errors : error.message,
              })
            },
          )
          .then(() => {
            dispatch('role/BOOTSTRAP', null, { root: true })
            commit(SET_DIALOG, { dialog: false })
          })
        },
      })
    },
    [OPEN_FORM] ({ dispatch, commit, state }, { item }) {
      commit(SET_DIALOG, { dialog: true })
    },
  },
}
